var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        { attrs: { id: "dialog" } },
        [
          _c(
            "rs-dialog",
            {
              ref: _vm.ref,
              class: _vm.className,
              attrs: {
                visible: _vm.visible,
                width: _vm.width,
                title: _vm.title,
                fullscreen: _vm.fullscreen,
                top: _vm.top,
                modal: _vm.modal,
                "modal-append-to-body": _vm.modalAppendToBody,
                "append-to-body": _vm.appendToBody,
                "lock-scroll": _vm.lockScroll,
                "custom-class": _vm.customClass,
                "close-on-click-modal": _vm.closeOnClickModal,
                "close-on-press-escape": _vm.closeOnPressEscape,
                "show-close": _vm.showClose,
                "before-close": _vm.beforeClose,
                center: _vm.center,
                "destroy-on-close": _vm.destroyOnClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visible = $event
                },
                open: _vm.open,
                opened: _vm.opened,
                close: _vm.close,
                closed: _vm.closed,
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "dialog-title",
                  attrs: { slot: "title" },
                  slot: "title",
                },
                [
                  _c("span", { staticStyle: { "font-size": "16px" } }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.iconType == "error",
                            expression: "iconType == 'error'",
                          },
                        ],
                      },
                      [
                        _c("i", {
                          staticClass: "rs-icon-error",
                          staticStyle: {
                            color: "#d82d33",
                            "font-size": "25px",
                            "margin-right": "10px",
                            "vertical-align": "middle",
                          },
                        }),
                        _vm._v(
                          "\n          " + _vm._s(_vm.title) + "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.iconType == "info",
                            expression: "iconType == 'info'",
                          },
                        ],
                      },
                      [
                        _c("i", {
                          staticClass: "rs-icon-info",
                          staticStyle: {
                            color: "#108EE9",
                            "font-size": "25px",
                            "margin-right": "10px",
                            "vertical-align": "middle",
                          },
                        }),
                        _vm._v(
                          "\n          " + _vm._s(_vm.title) + "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.iconType == "success",
                            expression: "iconType == 'success'",
                          },
                        ],
                      },
                      [
                        _c("i", {
                          staticClass: "rs-icon-success",
                          staticStyle: {
                            color: "#7ac654",
                            "font-size": "25px",
                            "margin-right": "10px",
                            "vertical-align": "middle",
                          },
                        }),
                        _vm._v(
                          "\n          " + _vm._s(_vm.title) + "\n        "
                        ),
                      ]
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.iconType == "warning",
                            expression: "iconType == 'warning'",
                          },
                        ],
                      },
                      [
                        _c("i", {
                          staticClass: "rs-icon-warning",
                          staticStyle: {
                            color: "#fbb932",
                            "font-size": "25px",
                            "margin-right": "10px",
                            "vertical-align": "middle",
                          },
                        }),
                        _vm._v(
                          "\n          " + _vm._s(_vm.title) + "\n        "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm.textBody !== ""
                ? _c("div", { staticClass: "dialog-body" }, [
                    _vm._v(_vm._s(_vm.textBody)),
                  ])
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "border-top": "none" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  !_vm.btnType
                    ? _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "rs-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v(_vm._s(_vm.cancelText))]
                          ),
                          _c(
                            "rs-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.confirm },
                            },
                            [_vm._v(_vm._s(_vm.confirmText))]
                          ),
                        ],
                        1
                      )
                    : _vm.btnType === 1
                    ? _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "rs-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.confirm },
                            },
                            [_vm._v(_vm._s(_vm.buttonResouce.confirmText))]
                          ),
                        ],
                        1
                      )
                    : _vm.btnType === 2
                    ? _c(
                        "div",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "rs-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.cancel },
                            },
                            [_vm._v("取 消")]
                          ),
                          _c(
                            "rs-button",
                            {
                              attrs: { type: "primary", size: "mini" },
                              on: { click: _vm.confirm },
                            },
                            [_vm._v("删 除")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }