var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "rs-toast" }, [
        _c("i", { staticClass: "rs-icon-success rs-toast-icon" }),
        _c("span", [_vm._v(_vm._s(_vm.message))]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }