var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("rs-org-select", {
        ref: "tree",
        attrs: {
          treeData: _vm.menuData[0],
          multiple: "",
          placeholder: "请选择菜单",
        },
        on: { change: _vm.clickDeal },
        model: {
          value: _vm.menuCheckedList,
          callback: function ($$v) {
            _vm.menuCheckedList = $$v
          },
          expression: "menuCheckedList",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }