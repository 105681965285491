<template>
  <div id="app" ref="containerS">
    <router-view />
  </div>
</template>
<script>
import Watermark from "@/utils/watermark.js";
import moment from "moment";
import Utils from "@/utils/utils";
export default {
  data() {
    return {
      username: "", // 这里设置用户名作为水印内容
    };
  },
  // watch: {
  //   $route(to, from) {
  //     if (!Utils.getLocalStorage('isWaterMark')) {
  //       this.$nextTick(function () {
  //         let userInfo = Utils.getSession('userInfo')
  //         this.username = userInfo.loginName + ' ' + userInfo.userName + ' ' + moment().format('YYYY/MM/DD')
  //         Watermark.set(this.username, this.$refs.containerS);
  //       });
  //     } else {
  //       if (this.username !== '') {
  //         Utils.setLocalStorage('isWaterMark', true)
  //       }
  //     }
  //   },
  // },
  mounted() {
    this.$nextTick(function () {
      setTimeout(() => {
        let userInfo = Utils.getSession("userInfo");
        let leftMenuInit = window.sessionStorage.getItem("leftMenuInit");
        console.log("leftMenuInit", leftMenuInit, leftMenuInit.includes("newWorkBench"));
        this.username =
          userInfo.loginName +
          " " +
          userInfo.userName +
          " " +
          moment().format("YYYY/MM/DD");
        if (leftMenuInit.includes("newWorkBench")) {
          Watermark.set(this.username, this.$refs.containerS);
        }
      }, 500);
    });
  },
  created() {
    // let {dashboardcontrol} = this.$route.query;
    // console.log(2);
    // console.log(dashboardcontrol);
    // if(dashboardcontrol){
    // localStorage.setItem('dashboardcontrol', dashboardcontrol);
    // let _script=document.createElement('script');
    // _script.setAttribute('charset','gbk');
    // _script.setAttribute('type','text/javascript');
    // _script.setAttribute('src',`//navi-oss.aimall.cloud/boss-dashboard/lib/dashboard-control.js?timestamp=${(new Date()).getTime()}`);
    // document.getElementsByTagName('head')[0].appendChild(_script);
  },
  // }
};
// setTimeout(function(){
//   let userInfo = Utils.getSession('userInfo')
//       let username = userInfo.loginName + ' ' + userInfo.userName + ' ' + moment().format('YYYY/MM/DD')
//       Watermark.set(username, document.getElementById('app'));
// },1500)
</script>
<style src="../public/font/iconfont.css"></style>
<style src="../public/font/fonts/iconfont.css"></style>
<style lang="less">
#app {
  width: 100%;
  height: 100%;
}
.rs-main {
  height: calc(100% - 20px) !important;
  box-shadow: none !important;
}
.home-main {
  padding: 20px 20px 0px;
  background-color: rgb(242, 243, 245) !important;
}
.rs-tabs__content {
  padding: 10px !important;
  background-color: #fff;
}

.menuShouqi {
  color: rgb(78, 89, 105) !important;
}
// 修改了输入框样式
.rs-input__inner {
  display: inline-flex !important;
  box-sizing: border-box !important;
  width: 100% !important;
  padding-right: 12px !important;
  padding-left: 12px !important;
  color: rgb(29, 33, 41) !important;
  font-size: 14px !important;
  background-color: rgb(242, 243, 245) !important;
  border: 1px solid transparent !important;
  border-radius: 2px !important;
  cursor: text !important;
}
// 修改重置按钮样式
.rs-button--text {
  color: rgb(78, 89, 105) !important;
  background-color: rgb(242, 243, 245) !important;
}

// 顶部tab栏样式修改
.tab-top {
  padding: 2px 0px !important;
  border-bottom: 1px solid #cccccc;
}
</style>
