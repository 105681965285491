// Dialog.js
import Dialog from './confirmDialog'
import Vue from 'vue'

let d // 组件示例
const DialogInstance = Vue.extend(Dialog) // 组件构造函数

const initInstance = () => { // 执行方法后完成挂载
  d = new DialogInstance()  // 实例化
  // top.document.body.appendChild(d.$mount().$el)
  document.body.appendChild(d.$mount().$el)
  // 实例化后手动挂载，得到$el真实Dom，将其添加到body最后
}

export default options => { //导出一个方法，接受配置参数
  if (!d) {
    initInstance(); // 挂载
  }
  Object.assign(d, options)
  // 实例化后 d 就是一个对象了，所以data内的数据会
  // 挂载到this下，传入一个对象与之合并
  return d.show(vm => {  // 显示弹窗
    d = null;  // 将实例对象清空
  })
}