<template>
  <div class="rs-toast" v-if="show">
    <i class="rs-icon-success rs-toast-icon"></i>
    <span>{{message}}</span>
  </div>
</template>

<script>
export default {
  name: 'RsMessageToast',
  data() {
    return {
      message: '',
      show: false
    }
  }
}
</script>

<style lang="less" scoped>
.rs-toast {
  position: fixed;
  top: 40%;
  left: 40%;
  width: 317px;
  height: 108px;
  background: #000;
  color: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  .rs-toast-icon {
    font-size: 34px;
    color: #fff;
    margin-right: 37px;
  }
}
</style>