var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "icon" },
      _vm._l(_vm.iconArray, function (item, index) {
        return _c("i", {
          key: index,
          staticClass: "font_family icon_block",
          domProps: { innerHTML: _vm._s(item) },
          on: {
            click: function ($event) {
              return _vm.chooseIcon(item)
            },
          },
        })
      }),
      0
    ),
    _c("span", {
      staticClass: "drawer-button",
      attrs: { slot: "footer" },
      slot: "footer",
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }