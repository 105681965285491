<template>
    <div class="">
        <rs-form :model="formData" label-width="155px" size="small" style="padding-right: 108px;" status-icon :rules="rules" ref="roleForm" class="commom-form demo-ruleForm">
            <rs-form-item label="角色名称" prop="roles">
                <rs-select
                    v-model="formData.roles"
                    filterable
                    remote
                    ref="selectRoles"
                    reserve-keyword
                    popper-class="dialog-popper"
                    :popper-append-to-body="true"
                    placeholder="请选择角色">
                    <rs-option
                        v-for="item in rolesList"
                        :key="item.id"
                        :label="item.roleName"
                        :value="item.id">
                    </rs-option>
                </rs-select>
            </rs-form-item>
        </rs-form>
    </div>
</template>

<script>
import userApi from '@/api/account'
export default {
    data() {
        return {
            rolesList: []
        }
    },
    props: {
        formData: {
            type: Object,
            default: ()=>{}
        },
        rules: {
            type: Object,
            default: ()=>{}
        },
    },
    methods: {
        handleQueryRoles(query) {
            setTimeout(() => {
                userApi.queryRolesByRoleName(query).then(res=>{
                    this.rolesList = res.data.data
                })
            }, 200);
        },
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {
        this.handleQueryRoles('')
    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
    }
}
</script>
<style scoped>
/* @import url(); 引入css类 */

</style>
