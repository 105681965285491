import Http from '../utils/axios'
const rt = '/api'
const user = '/user'
export default {
    /*--------全局接口-----------*/
    /*-------E端登录------*/
    getUserInfoList(params){
        return Http.post(`${rt}${user}/v1/e/userInfo/getUserInfoList`,params,true)
    },
    // 禁用启用账号
    changeStatus(params) {
        return Http.post(`${rt}${user}/v1/e/userInfo/updateStatus`, params, false)
    },
    /*------更新账号信息------*/
    updateInfo(params){
        return Http.post(`${rt}${user}/v1/e/userInfo/saveOrUpdate`,params)
    },
    /*------删除账号信息------*/
    delAccount(params){
        return Http.post(`${rt}${user}/v1/e/userInfo/deleteUser?userId=${params.userId}`, '', true)
    },
    /*------获取角色列表------*/
    getRoleList(params){
        return Http.post(`${rt}${user}/v1/e/role/findPage`,params,true)
    },
    // 新增和编辑角色
    setRoles(params) {
        return Http.post(`${rt}${user}/v1/e/role/saveOrUpdate`, params, false)
    },
    /*------获取账号详情------*/
    getUserInfoDetail(params){
        return Http.get(`${rt}${user}/v1/e/userInfo/getUserDetail`,params,true)
    },
    // 商场数据权限
    queryMallsAuth(userId) {
        return Http.get(`${rt}${user}/v1/e/mall/mallAuthoriList?userId=${userId}`, false, false)
    },
    // 角色数据权限
    queryRolesAuth(userId) {
        return Http.get(`${rt}${user}/v1/e/role/getUserAuthoriRole?userId=${userId}`, false, false)
    },
    // 取消权限
    cancelAuth(params) {
        return Http.post(`${rt}${user}/v1/e/privilege/cancelPrivilege`, params, false)
    },
    // 添加权限
    addAuth(params) {
        return Http.post(`${rt}${user}/v1/e/privilege/addPrivilege`, params, false)
    },
    // 根据角色名字查询角色
    queryRolesByRoleName(roleName) {
        return Http.get(`${rt}${user}/v1/e/role/getListByRoleName?roleName=${roleName}`, false, false)
    },
    // 用户确定授权角色
    setBindRole(params) {
        return Http.post(`${rt}${user}/v1/e/role/v1/e/role/userBingRole`, params, false)
    },
    // 用户移除角色权限
    setUnbindRole(params) {
        return Http.post(`${rt}${user}/v1/e/role/v1/e/role/userUnBingRole`, params, false)
    },
    // 权限授权
    setAuth(params) {
        return Http.post(`${rt}${user}/v1/e/privilege/addPrivilegeWithApplication`, params, false)
    },
    // 授权组织架构
    addPrivilegeCommon(params) {
        return Http.post(`${rt}${user}/v1/e/privilege/addPrivilegeCommon`,params,false)
    },
    // 获取已授权过的组织架构
    getUserPrivilegeAndOpenOrgan(params) {
        return Http.get(`${rt}${user}/v1/e/privilege/getUserPrivilegeAndOpenOrgan`,params,false)
    },
    // 重置密码
    resetPwd(params) {
        return Http.post(`${rt}${user}/v1/e/userInfo/resetPassword`,params,false)
    },
}
