<template>
    <div>
        <rs-org-select
            :treeData="menuData[0]"
            ref="tree"
            multiple
            v-model="menuCheckedList"
            placeholder="请选择菜单"
            @change="clickDeal"
          ></rs-org-select>
    </div>
</template>

<script>
    import utils from '@/utils/utils'
    import userApi from '@/api/api'
    export default {
        props: {
            type: {
                type: String,
                default: 'role'
            },
            userId: {
                type: [String, Number],
                default: ''
            }
        },
        data(){
            return{
                readonly: false,
                paramId: '',
                applicationList: [],
                menuData: [],
                buttonList:[], 
                selectApplication: '',
                selectTreeNodeKey: '',
                resourceTypes: [],
                checkApplication: 0,
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                checkedMenu: {
                    buttonList: []
                },
                menuCheckedList: [],
                buttons: [],
                buttonCheckFlag: false,
                menuHalfCheckedList: [],
                menuSendList: [],
                resourceSendTypes: [],
                authLoading: false,
            }
        },
        methods:{
            // 获取应用模块
            handleAuthData() {
                this.handleMenusAndButtons(1, 0)
            },
            // 角色新增时没有operationId，请求所有菜单按钮资源
            handleAllResource() {
                userApi.initAllResourceTree({}).then((res)=>{
                    let menus = []
                    res.data.data.childResourceVoList.forEach(item=>{
                        menus.push(this.handleMenu(item, 1))
                    })
                    this.$set(this.menuData, 0, menus)
                })
            },
            handleMenusAndButtons(val, index) {
                let type = this.type
                let params = {
                    "applicationId": val,
                    "operationId": this.paramId,
                    "operationType": type == 'user' ? 0 : 1
                }
                userApi.queryMenuAndButton(params).then((res)=>{
                    if (res.data && res.data.data) {
                        let menus = []
                        res.data.data.resourceVo.childResourceVoList.forEach(item=>{
                            menus.push(this.handleMenu(item, val))
                        })
                        this.$set(this.menuData, index, menus)
                    }
                }).catch(err=>{
                    
                });
            },
            clickDeal () {
                let halfChecked = this.$refs.tree.getHalfCheckedNodes().filter(item=>!item.disabled)
                let checked = this.$refs.tree.getCheckedNodes().filter(item=>!item.disabled)
                let data = [...halfChecked, ...checked]
                let type = this.type
                let params = {
                    operationId: this.paramId,
                    operationType: type == 'user' ? 0 : 1,
                    applicationPrivilegeVoList: []
                }
                let obj = {}
                obj = {
                    applicationId: 1,
                    privilegeVoList: []
                }
                obj.privilegeVoList.push({
                    accessId:1,accessType: 0, masterId: 1*this.paramId, operationType: 2
                })
                data.forEach(item=>{
                    obj.privilegeVoList.push({
                        accessId:1*item.id,accessType: 1, masterId: 1*this.paramId, operationType: 2
                    })
                })
                params.applicationPrivilegeVoList.push(obj)
                this.$emit('authData', params)
            },
            // 菜单树形处理
            handleMenu(el, applicationId) {
                el.menuList = []
                el.buttonList = []
                if(!el.rootTree) {
                    el.rootTree = applicationId
                }
                if(!el.treeId) {
                    el.treeId = `${applicationId}-${el.parentResourceId}-${el.id}`
                }
                el.parentResourceId = `${el.parentResourceId}-${el.id}`
                if(el.childResourceVoList&& el.childResourceVoList.length > 0) {
                    el.menuList = el.childResourceVoList.filter(item=>item.resourceType == 1)
                    el.buttonList = el.childResourceVoList.filter(item=>item.resourceType == 2)
                }
                if(el.choiceFlag) {
                    if(el.childResourceVoList && el.childResourceVoList.length == 0 || !el.childResourceVoList) {
                        this.menuCheckedList.push(el.id)
                    }
                }
                const haveChildren = Array.isArray(el.childResourceVoList)
                return {
                    disabled: this.readonly||el.prohibitFlag?true:false,
                    checked: el.choiceFlag,
                    label: `${el.resourceName}`,
                    id: el.id,
                    value: `${el.resourceCode}`,
                    resourceCode: el.resourceCode,
                    resourceType: el.resourceType,
                    parentResourceId: `${applicationId}-${el.parentResourceId}`,
                    childResourceVoList: { ...el },
                    children: { ...el },
                    menuList: el.menuList,
                    buttonList: el.buttonList,
                    treeId: el.treeId,
                    rootTree: el.rootTree,
                    choiceFlag: el.choiceFlag,
                    children:haveChildren ? el.childResourceVoList.map(i => this.handleMenu(i, applicationId)) : null
                }
            },
        },
        created(){
            if(this.$route) {
                if(this.$route.query.id) {
                    this.paramId = this.$route.query.id
                    this.handleAuthData()
                } else {
                    this.paramId = ''
                    this.handleAllResource()
                }
                if(this.$route.params.type==='view' || this.$route.params.type==='auth' ) this.readonly = true
            } else {
                this.paramId = this.userId
                this.handleAuthData()
            }
        },
    }
</script>

<style lang="less" scoped>
</style>
