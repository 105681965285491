import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* eslint-disable */
export default new Router({
  routes: [
    {
      path: '/main',
      name: 'main',
      component: () => import('./views/home/main.vue')
    },
    // {
    //     path: "/",
    //     name: "nav",
    //     component: () =>
    //         import ("./views/home/nav.vue")
    // },
    {
      path: '/',
      name: 'home',
      component: () => import('./views/home/home.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/login/login.vue')
    },
    /**---账号管理列表---**/
    {
      path: '/accountManageList',
      name: 'accountManageList',
      component: () => import('./views/accountManage/accountManage/accountManageList.vue')
    },
    /**---账号管理详情---**/
    {
      path: '/accountManageDetail/:type',
      name: 'accountManageDetail',
      component: () => import('./views/accountManage/accountManage/accountManageDetail.vue')
    },
    /**---账号管理编辑---**/
    {
      path: '/accountManageEdit',
      name: 'accountManageEdit',
      component: () => import('./views/accountManage/accountManage/edit.vue')
    },
    /**---角色管理列表---**/
    {
      path: '/roleManageList',
      name: 'roleManageList',
      component: () => import('./views/accountManage/roleManage/roleManageList.vue')
    },
    /**---角色管理详情---**/
    {
      path: '/roleManageDetail/:type',
      name: 'roleManageDetail',
      component: () => import('./views/accountManage/roleManage/roleManageDetail.vue')
    },
    {
      path: '/roleDetail/:type',
      name: 'roleDetail',
      component: () => import('./views/accountManage/roleManage/detail.vue')
    },
    // 企业组织管理
    {
      path: '/organizationManage',
      name: 'organizationManage',
      component: () => import('./views/organizationManage/organizationList.vue')
    },
    {
      path: '/organizationEdit',
      name: 'organizationEdit',
      component: () => import('./views/organizationManage/edit.vue')
    },
    {
      path: '/organizationDetail',
      name: 'organizationDetail',
      component: () => import('./views/organizationManage/organizationDetail.vue')
    },
    // 岗位详情
    {
      path: '/stationDetail',
      name: 'stationDetail',
      component: () => import('./views/organizationManage/stationDetail.vue')
    },
    {
      path: '/stationEdit',
      name: 'stationEdit',
      component: () => import('./views/organizationManage/stationEdit.vue')
    },
    /**---资源管理---**/
    {
      path: '/resourceManage',
      name: 'resourceManage',
      component: () => import('./views/systemManage/resourceManage/resourceManage.vue')
    },
    {
      path: '/resourceEdit',
      name: 'resourceEdit',
      component: () => import('./views/systemManage/resourceManage/edit.vue')
    },
    // 证大跳转空页面路由
    {
      path: '/unionLogin',
      name: 'unionLogin',
      component: () => import('./views/callBack.vue')
    },
    // 下载页面
    {
      path: '/download',
      name: 'download',
      component: () => import('./views/downloadPages/download.vue')
    },
    {
      path: '/reportList',
      name: 'reportList',
      component: () => import('./views/reportServer/list.vue')
    },
    {
      path: '/table',
      name: 'table',
      component: () => import('./views/table.vue')
    },
    /**---预警管理---**/
    {
      path: '/earlywarning',
      name: 'earlywarning',
      component: () => import('./views/warningManage/index.vue')
    },
    {
      path: '/editearlywarning',
      name: 'editearlywarning',
      component: () => import('./views/warningManage/edit.vue')
    },
    {
      path: '/imgSetter',
      name: 'imgSetter',
      component: () => import('./views/home/imgSetter.vue')
    },
    /**日志管理 */
    {
      path: '/loginLog',
      name: 'loginLog',
      component: () => import('./views/logManage/loginlog.vue')
    },
    {
      path: '/workLog',
      name: 'workLog',
      component: () => import('./views/logManage/workLog.vue')
    },
    /*
     ** 新增导航页
     */
    // {
    //     path: "/",
    //     name: "nav",
    //     component: () =>
    //         import ("./views/home/nav.vue")
    // },
    {
      path: '/ticket',
      name: 'ticket',
      component: () => import('./views/ticketSet.vue')
    },
    /**---报表中心配置---**/
    {
      path: '/reportCenterConfig',
      name: 'reportCenterConfig',
      component: () => import('./views/reportCenterConfig/index.vue')
    },
    {
      path: '/editReportCenterConfig',
      name: 'editReportCenterConfig',
      component: () => import('./views/reportCenterConfig/edit.vue')
    },
    {
      path: '/addReportCenterConfig',
      name: 'addReportCenterConfig',
      component: () => import('./views/reportCenterConfig/add.vue')
    },
    //初始化配置
    {
      path: '/initialSetting',
      name: 'initialSetting',
      component: () => import('./views/initialSetting/index.vue')
    },
    {
      path: '/sysSettingDetail',
      name: 'sysSettingDetail',
      component: () => import('./views/initialSetting/components/systemSettingDetail.vue')
    },
    {
      path: '/mapSettingDetail',
      name: 'mapSettingDetail',
      component: () => import('./views/initialSetting/components/mapSettingDetail.vue')
    },
    //三方平台配置
    {
      path: '/thirdConfigInfo',
      name: 'initialSetting',
      component: () => import('./views/initialSetting/thirdConfigInfo.vue')

    },
    {
      path: '/paySettingDetail',
      name: 'paySettingDetail',
      component: () => import('./views/initialSetting/components/paySettingDetail.vue')
    },
  ]
})
