import Http from "../utils/axios";
const rt = "/api";
const user = "/user";
export default {
    /*** sso ***/
    //校验当前用户是否已登录
    checkIsLogin(params) {
        return Http.post(`${rt}${user}/v1/e/config/api/findConfigByConfigGroup`, params);
    },
    //验证第三方可信接口
    ssoValidate(params) {
        return Http.post(`${rt}${user}/e/noauth/sso/validate`, params);
    },
    // // 生成code
    ssoGetCode(params) {
        return Http.get(`${rt}${user}/e/sso/genCode`, params,true);
    },
    /*--------全局接口-----------*/
    /*-------E端登录------*/
    login(params) {
        return Http.post(`${rt}${user}/e/login`, params);
    },
    /*-----获取按钮权限列表--------*/
    getButtonResource() {
        return Http.get(`${rt}${user}/v1/e/resource/getButtonResource`);
    },
    // 获取平台菜单权限列表
    getMenuResource(params) {
        return Http.get(
            `${rt}${user}/v1/e/application/initLoginResourceTree`,
            params,
            false
        );
    },
    /*-------E端登出------*/
    logout(params) {
        return Http.get(`${rt}${user}/e/logout`, params);
    },
    /*-------修改密码------*/
    modifyPwd(params) {
        return Http.post(`${rt}${user}/v1/e/userInfo/modifyPassword`, params);
    },
    // 登录发送短信验证码
    sendVerifyCodeByLogin(params){
        return Http.get(`${rt}${user}/v1/e/verify/sendVerifyCodeByLogin?mobile=${params}`,false,false)
    },
    // 修改密码发送短信验证码
    sendVerifyCodeByModifyPwd(params) {
        return Http.get(
            `${rt}${user}/v1/e/verify/sendVerifyCodeByModifyPwd?mobile=${params}`,
            false,
            false
        );
    },
    /*------加载所有资源树------*/
    initAllResourceTree(params, param) {
        return Http.get(
            `${rt}${user}/v1/e/resource/initAllResourceTree?${param}`,
            params,
            false
        );
    },
    /*------加载上级资源列表------*/
    getAllResourceList(params, param) {
        return Http.get(`${rt}${user}/v1/e/resource/getAllResourceList?${param}`, params);
    },
    /*------新增资源-----------*/
    addResource(params) {
        return Http.post(`${rt}${user}/v1/e/resource/addResource`, params, true);
    },
    /*------更新资源-----------*/
    updateResource(params) {
        return Http.post(`${rt}${user}/v1/e/resource/updateResource`, params, true);
    },
    /*------删除资源-----------*/
    delResource(params) {
        return Http.post(`${rt}${user}/v1/e/resource/deleteResource`, params, true);
    },
    /*------更新资源排序-----------*/
    updateResourceSort(params) {
        return Http.post(`${rt}${user}/v1/e/resource/updateResourceSort`, params, true);
    },
    /*------新增角色------*/
    addRole(params) {
        return Http.post(`${rt}${user}/v1/e/role/saveOrUpdate`, params);
    },
    /*------角色详情------*/
    findRoleDetail(params) {
        return Http.post(`${rt}${user}/v1/e/role/findRoleDetail`, params);
    },
    // 获取角色拥有的用户列表
    queryUsersByRole(params) {
        return Http.get(
            `${rt}${user}/v1/e/role/getUserListByRoleId`,
            params,
            false
        );
    },
    // 根据应用模块查询菜单和按钮权限
    queryMenuAndButton(params) {
        return Http.post(
            `${rt}${user}/v1/e/application/initResourceTreeByApplicationId`,
            params,
            false
        );
    },
    /*-----------下载页面接口---------*/
    ExcelDownload(params) {
        return Http.post(`${rt}${user}/v1/e/excel/queryExcelDownload`, params, true);
    },
    ExcelModule(params) {
        return Http.post(`${rt}${user}/v1/e/excel/queryExcelModule`, params);
    },
    //根据用户loginName获取最新session
    findSysUserInfo(params){
        return Http.post(`${rt}${user}/v1/e/login/findSysUserInfo`,params)
    },
    /*-----------预警管理接口---------*/
    getWarningconfigType(params){         
        return Http.get(`${rt}${user}/v1/e/dict/findList`,params);        
    },
    queryWarinngList(params){
        return Http.post(`${rt}${user}/v1/e/mallconfig/api/findPage`, params);
    },
    addWarningList(params){
        return Http.post(`${rt}${user}/v1/e/mallconfig/api/saveOrUpdate`, params);
    },
    delWarning(params){
        return Http.post(`${rt}${user}/v1/e/mallconfig/api/delete`, params);
    },
    getWarningDetail(params){
        return Http.post(`${rt}${user}/v1/e/mallconfig/api/detail`, params);        
    },

    /*-----------日志列表---------*/
    querySysLogList(params){           //登陆日志
        return Http.post(`${rt}${user}/v1/e/log/inner/findSysLogPage`,params);        
    },
    queryBizLogList(params){           //业务日志
        return Http.post(`${rt}${user}/v1/e/log/inner/findBizLogPage`,params);        
    },
    exportSysLog(params){           //登陆日志导出
        return Http.post(`${rt}${user}/v1/e/log/inner/exportSysLog`,params);        
    },
    exportBizLog(params){           //业务日志导出
        return Http.post(`${rt}${user}/v1/e/log/inner/exportBizLog`,params);        
    },
};