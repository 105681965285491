import axios from 'axios'
import { RsMessage } from 'redstarcloud-ui-beta'
import utils from './utils'
import store from "@/store";

function loadingShowOrHide(show) {
    if (show) {
        utils.showLoading()
    } else {
        utils.hideLoading()
    }
}

axios.defaults.timeout = 15000
axios.defaults.withCredentials = true
axios.defaults.responseType = 'json'
axios.defaults.headers.common['Cache-Control'] = 'no-cache'
axios.defaults.headers.get['If-Modified-Since'] = '0'
axios.defaults.cache = false
// http response 拦截器
axios.interceptors.response.use(
    response => {
        // 接口拦截，用户未登陆
        if (response.data.type === 'no_login') {
            localStorage.removeItem('userInfo')
            if(utils.getLocalStorage('autoLoginUrl',false)){
                window.location.href = utils.getLocalStorage('autoLoginUrl',false)
                // top.location.reload()
                return;
            }
            if(response.data.message=="退出成功"){
                top.location.reload()
                return
            }else{
                top.$message.error(response.data.message || '用户登陆失败，IP锁定中，请等待48小时后再试！');
            setTimeout(function(){
                top.location.reload()
                return
            },2000)
        }
            // 接口拦截，后端抛出错误信息
        } else if (response.data.code !== 200 &&  response.data.code !== -400400) {
            top.$message.error(response.data.message || '系统异常！');
            // RsMessage.error(response.data.message || '系统异常！');
            return Promise.reject(response.data)
        } else {
            return response
        }
    },
    error => {
        return Promise.reject(error.response.data)   // 返回接口返回的错误信息
    })

/*
loading为true 调用这个api会打开loading，结束关闭
loading为false  调用只会关闭loading ,主要用于首次打开tab页面，关闭loading
loading为''或者不传  什么都不操作
 */
export default class Http {

    static send(config, loading) {
        // const currentUrl = encodeURIComponent(window.location.href)

        const configs = Object.assign({
            headers: {
                'currentUrl': window.location.href,
                'L-A-Platform': 'erp-web',//后端日志埋点渠道
                'Access-Control-Allow-Origin':'*',
                'Authorization': utils.getLocalStorage('aegeanUserInfo',false),
            },
            timeout: 30000
        }, config)
        let user = store.state.oaQuery
        if (user && user.token) {
            configs.headers.token = user.token
            configs.headers.appId = user.appId
        }
        if (loading) {
            loadingShowOrHide(true)
        }
        return axios(configs).then((res) => {
            if (loading === '' || typeof loading == 'object' || loading === undefined) {
                // 什么都不操作
            } else {
                loadingShowOrHide()
            }
            return res
        }).catch((error) => {
            if (loading === '' || typeof loading == 'object' || loading === undefined) {
                // 什么都不操作
            } else {
                loadingShowOrHide()
            }
            if (error) {
                console.log('error',error);
                switch (error.code) {
                    case 500:
                        top.$message.error(error.message || '系统异常！');
                        break;
                    case 404:
                        top.$message.error(error.message || '网络异常！');
                        break;
                }
            }
            throw error
        })
    }

    static post(url, params = {}, loading) {
        const config = {
            method: 'post',
            url,
            data: params
        }
        return Http.send(config, loading)
    }

    static get(url, params = {}, loading) {
        let urlParams = []
        Object.keys(params).forEach((key) => {
            urlParams.push(`${key}=${encodeURIComponent(params[key])}`)
        })
        if (urlParams.length) {
            urlParams = `${url}?${urlParams.join('&')}`
        } else {
            urlParams = url
        }
        const config = {
            url: urlParams,
            params: {
                randomTime: new Date().getTime() // 防止缓存
            }
        }
        return Http.send(config, loading)
    }
}
