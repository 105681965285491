<template>
  <div id="mallSelect">
    <rs-select
      v-model="mallCode"
      @change="change"
      :disabled="disabled"
      :clearable="clearable"
      :collapse-tags="collapseTags"
      :filterable="filterable"
      :placeholder="placeholder"
    >
      <rs-option
        v-for="item in mallOptions"
        :key="item.id"
        :label="item.mallName"
        :value="item.mallCode"
      ></rs-option>
    </rs-select>
  </div>
</template>

<script>
import Http from "@/utils/axios";
import _ from "lodash";
export default {
  name: "rsSelectMall",
  props: {
    value: {
      type: [Number, String],
      default: ""
    },
    disabled: {
      //禁用
      type: Boolean,
      default: false
    },
    clearable: {
      //清空
      type: Boolean,
      default: true
    },
    multiple: {
      //多选
      type: Boolean,
      default: false
    },
    collapseTags: {
      //多选
      type: Boolean,
      default: false
    },
    filterable: {
      //搜索
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: "请选择项目名称"
    },
    host: {
      type: String,
      default: ""
    },
    // 是否默认展示
    mustFlag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mallOptions: [],
      mallCode: ""
    };
  },
  watch: {
    value: {
      handler: function(val, oldVal) {
        this.mallCode = val;
      },
      immediate: true
    }
  },
  methods: {
    change(val, type) {
      if(!val&&!type) {
        this.$emit('input', '')
        this.$emit("change", {mallCode: '', mallId: '', mallName: ''});
      } 
      val && this.getMallDetail(val);
    },
    async getMallDetail(code) {
      let res = await this.queryInvestMallDetailByCode(code);
      if (res.data.code == 200) {
        this.$emit("change", res.data.data);
        this.$emit('input', res.data.data.mallCode)
      }
    },
    queryInvestMallDetailByCode(params) {
      let host = this.host;
      host = host ? host.split("/") : "";
      host = _.dropRight(host, 1).join("/");
      return Http.get(
        `${host}/investment/e/mall/queryInvestMallDetailByCode?mallCode=${params}`,
        true
      );
    },
    getMallAuth(params) {
      return Http.get(`${this.host}/v1/e/mall/getMallAuthoriHomePage`, params);
    },
    async getMalls() {
      let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      let userId = userInfo ? userInfo.userId : 1;
      let res = await this.getMallAuth({ userId });
      if (res.data.code === 200) {
        this.mallOptions = res.data.data || [];
        if (this.mallOptions.length > 0) {
          if (!this.mustFlag) return;
          if (this.mallOptions.length==1) {
            this.mallCode = this.mallOptions[0].mallCode;
          }
          this.change(this.mallCode);
        } else {
          this.change('', 'check');
        }
      }
    }
  },
  created() {
    this.getMalls();
  }
};
</script>

<style scoped></style>
