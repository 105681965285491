<template>
    <div>
        <div class="icon">
            <i class="font_family icon_block" v-for="(item, index) in iconArray" :key="index" v-html="item" @click="chooseIcon(item)"></i>
        </div>
        <span slot="footer" class="drawer-button">
        <!-- <rs-button @click="hide">取 消</rs-button> -->
        <!--<rs-button type="primary"  @click="submitForm('ruleForm')">确 定</rs-button>-->
        </span>
    </div>
</template>

<script>
    export default {
        name: "iconDrawer",
        props:{
            formData: {
                type: Object,
                default: ()=>{}
            },
            rules: {
                type: Object,
                default: ()=>{}
            },
        },
        data(){
            return {
                iconArray:['&#xe669;','&#xe668;','&#xe667;','&#xe664;','&#xe663;','&#xe785;','&#xe661;','&#xe660;','&#xe65f;','&#xe65e;','&#xe65d;','&#xe65c;','&#xe65a;','&#xe657;','&#xe656;','&#xe655;','&#xe654;','&#xe652;',
                    '&#xe651;','&#xe650;','&#xe64f;','&#xe64e;','&#xe64d;','&#xe64c;','&#xe60f;','&#xe60e;','&#xe60c;','&#xe60a;','&#xe609;','&#xe608;','&#xe607;','&#xe606;','&#xe75a;','&#xe75b;','&#xe75c;','&#xe75c;','&#xe75d;',
                    '&#xe75e;','&#xe75f;','&#xe760;','&#xe761;','&#xe762;','&#xe763;','&#xe764;','&#xe765;','&#xe766;','&#xe767;','&#xe768;','&#xe769;','&#xe76a;','&#xe76b;','&#xe76c;','&#xe76d;','&#xe76e;','&#xe76f;','&#xe770;','&#xe771;',
                    '&#xe772;','&#xe773;','&#xe774;','&#xe775;','&#xe776;','&#xe777;','&#xe778;','&#xe779;'],
            }
        },
        methods:{
            chooseIcon(code){
                this.formData.iconCode = code
                this.hide()
            },
            hide(){
                this.$emit('closeIcon')
            }
        }
    }
</script>

<style scoped>
.icon{
    display: flex;
    justify-content: flex-start;
    padding:0 20px;
    flex-wrap: wrap;
}
.icon_block{
    width:20px;
    height:20px;
    font-size:20px;
    margin-right:20px;
    margin-bottom:20px;
    cursor: pointer;
}
</style>