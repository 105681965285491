<template>
  <div id="dialog" v-if="visible">
    <rs-dialog
      :ref="ref"
      :visible.sync="visible"
      :width="width"
      :title="title"
      :fullscreen="fullscreen"
      :class="className"
      :top="top"
      :modal="modal"
      :modal-append-to-body="modalAppendToBody"
      :append-to-body="appendToBody"
      :lock-scroll="lockScroll"
      :custom-class="customClass"
      :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape"
      :show-close="showClose"
      :before-close="beforeClose"
      :center="center"
      :destroy-on-close="destroyOnClose"
      @open="open"
      @opened="opened"
      @close="close"
      @closed="closed"
    >
      <span slot="title" class="dialog-title">
        <span style="font-size: 16px;">
          <span v-show="iconType == 'error'">
            <i
              style="color: #d82d33; font-size: 25px;margin-right: 10px; vertical-align: middle;"
              class="rs-icon-error"
            ></i>
            {{title}}
          </span>
          <span v-show="iconType == 'info'">
            <i
              style="color: #108EE9; font-size: 25px;margin-right: 10px; vertical-align: middle;"
              class="rs-icon-info"
            ></i>
            {{title}}
          </span>
          <span v-show="iconType == 'success'">
            <i
              style="color: #7ac654; font-size: 25px;margin-right: 10px; vertical-align: middle;"
              class="rs-icon-success"
            ></i>
            {{title}}
          </span>
          <span v-show="iconType == 'warning'">
            <i
              style="color: #fbb932; font-size: 25px;margin-right: 10px; vertical-align: middle;"
              class="rs-icon-warning"
            ></i>
            {{title}}
          </span>
        </span>
      </span>
      <div v-if="textBody !== ''" class="dialog-body">{{textBody}}</div>

      <span slot="footer" class="dialog-footer" style="border-top: none;">
        <div style="text-align: right" v-if="!btnType">
          <rs-button @click="cancel" size="mini">{{cancelText}}</rs-button>
          <rs-button type="primary" size="mini" @click="confirm">{{confirmText}}</rs-button>
        </div>
        <div style="text-align: right" v-else-if="btnType === 1">
          <rs-button type="primary" size="mini" @click="confirm">{{buttonResouce.confirmText}}</rs-button>
        </div>
        <div style="text-align: right" v-else-if="btnType === 2">
          <rs-button @click="cancel" size="mini">取 消</rs-button>
          <rs-button type="primary" size="mini" @click="confirm">删 除</rs-button>
        </div>
      </span>
    </rs-dialog>
  </div>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return {
      src: "",
      visible: false,
      title: "",
      width: "50%",
      fullscreen: false,
      top: 0,
      modal: true,
      modalAppendToBody: false,
      appendToBody: false,
      lockScroll: true,
      customClass: "",
      closeOnClickModal: true,
      closeOnPressEscape: true,
      showClose: true,
      center: false,
      destroyOnClose: false,
      className: "",
      height: "",
      ref: "dialog",
      container: null,
      params: {},
      value: "",
      textBody: "",
      btnType: 0,
      zIndex: 0,
      iconType: "",
      info: {},
      form: {},
      confirmText: "确 定",
      cancelText: "取 消",
      buttonResouce: {
        confirmText: "我知道了"
      }
    };
  },
  created() {
    console.clear();
    const dialog = document.getElementById("dialog");
    if (dialog) document.body.removeChild(dialog);
  },
  methods: {
    cancel() {
      this.reject("cancel"); // 抛个字符串
      this.hide("cancel");
    },
    confirm() {
      this.resolve(this.formData); // 抛出数据
      this.hide();
    },
    show(cb) {
      this.visible = true;
      typeof cb === "function" && cb.call(this, this);
      return new Promise((resolve, reject) => {
        // 返回Promise
        this.reject = reject; // 给取消按钮使用
        this.resolve = resolve; // 给确认按钮使用
      });
    },
    hide(type) {
      this.visible = false;
      const dialog = document.getElementById("dialog");
      const dialogChild = document.querySelector("#dialog .rs-dialog__wrapper");
      dialog.removeChild(dialogChild); // 结束移除Dom
      let drawerDom = document.querySelector("#drawerId");
      if (!drawerDom) {
        const dialogChildSec = document.querySelector("#dialog .v-modal");
        dialogChildSec && dialog.removeChild(dialogChildSec); // 结束移除Dom
      }
      this.$destroy(); // 执行组件销毁
    },
    open() {},
    opened() {},
    close() {
      this.hide();
    },
    closed() {},
    beforeClose(done) {
      done();
    }
  }
};
</script>
<style lang="less" scoped>
</style>
