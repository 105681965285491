var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "rs-form",
        {
          ref: "roleForm",
          staticClass: "commom-form demo-ruleForm",
          staticStyle: { "padding-right": "108px" },
          attrs: {
            model: _vm.formData,
            "label-width": "155px",
            size: "small",
            "status-icon": "",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "rs-form-item",
            { attrs: { label: "角色名称", prop: "roles" } },
            [
              _c(
                "rs-select",
                {
                  ref: "selectRoles",
                  attrs: {
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    "popper-class": "dialog-popper",
                    "popper-append-to-body": true,
                    placeholder: "请选择角色",
                  },
                  model: {
                    value: _vm.formData.roles,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "roles", $$v)
                    },
                    expression: "formData.roles",
                  },
                },
                _vm._l(_vm.rolesList, function (item) {
                  return _c("rs-option", {
                    key: item.id,
                    attrs: { label: item.roleName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }