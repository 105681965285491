import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Tab from './utils/tabSwitch'
import Filter from './utils/filters'
import Global from './utils/global'
import echarts from 'echarts'
import { log } from 'util'
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
import './assets/css/index.less'
import confirmDialog from '@/components/confirmDialog'
import DialogManage from '@/components/dialogManage'
import redstarcloudUi from 'redstarcloud-ui-beta'
import 'redstarcloud-ui-beta/dist/redstarcloud-ui-beta.css'
import rsMessageToast from '../src/components/rsMessageToast/src/index'
import store from '@/store'
import vueiInfinite from 'vue-infinite-scroll'


window.mallHost = '/api/investment';
Vue.use(vueiInfinite)
Vue.use(redstarcloudUi)
Vue.use(rsMessageToast)

require('es6-promise').polyfill()
Es6Promise.polyfill()
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

Vue.prototype.$confirmDialog = confirmDialog
Vue.prototype.$dialogManage = DialogManage

// window.addEventListener('keydown', data => {
//   if (data.key === 'Tab') {
//     window.event.preventDefault()
//     window.event.stopPropagation()
//     return false
//   }
// })

Vue.use(Tab)
Vue.use(Global)
Vue.use(Filter)
/* eslint-disable */
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
