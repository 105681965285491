var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "mallSelect" } },
    [
      _c(
        "rs-select",
        {
          attrs: {
            disabled: _vm.disabled,
            clearable: _vm.clearable,
            "collapse-tags": _vm.collapseTags,
            filterable: _vm.filterable,
            placeholder: _vm.placeholder,
          },
          on: { change: _vm.change },
          model: {
            value: _vm.mallCode,
            callback: function ($$v) {
              _vm.mallCode = $$v
            },
            expression: "mallCode",
          },
        },
        _vm._l(_vm.mallOptions, function (item) {
          return _c("rs-option", {
            key: item.id,
            attrs: { label: item.mallName, value: item.mallCode },
          })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }