<template>
    <div>
        <auth :type="'user'" :userId="formData.operationId" @authData="authData"></auth>
    </div>
</template>

<script>
import auth from '../authDetail'
export default {
    components: {
        auth
    },
    props: {
        formData: {
            type: Object,
            default: ()=>{}
        },
    },
    data() {
        return {

        }
    },
    methods: {
        authData(data) {
            this.formData.applicationPrivilegeVoList = data
        }
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {
    },
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
    }
}
</script>
<style scoped>
/* @import url(); 引入css类 */

</style>