<template>
  <div id="dialog" v-if="visible">
    <rs-dialog
      :ref="ref"
      :visible.sync="visible"
      :width="width"
      :title="title"
      :fullscreen="fullscreen"
      :class="className"
      :top="top"
      :modal="modal"
      :modal-append-to-body="modalAppendToBody"
      :append-to-body="appendToBody"
      :lock-scroll="lockScroll"
      :custom-class="customClass"
      :close-on-click-modal="closeOnClickModal"
      :close-on-press-escape="closeOnPressEscape"
      :show-close="showClose"
      :before-close="beforeClose"
      :center="center"
      :destroy-on-close="destroyOnClose"
      @open="open"
      @opened="opened"
      @close="close"
      @closed="closed"
    >
      <components :formData="formData" :ref="type" :rules="rules" :is="type" v-if="type!=='IconDrawer'"/>
      <components :formData="formData" :ref="type" :rules="rules" @closeIcon="closeIcon" v-if="type==='IconDrawer'" :is="type" />
      <span slot="footer" class="dialog-footer">
        <rs-button size="small" @click="cancel">取消</rs-button>
        <rs-button size="small" type="primary" v-if="confirmFlag" @click="confirm">{{btnText}}</rs-button>
      </span>
    </rs-dialog>
  </div>
</template>

<script>
import utils from "@/utils/utils";
import roleBindForm from "@/views/accountManage/accountManage/components/roleBindForm";
import addUserAuth from "@/views/accountManage/accountManage/addAuth";
import IconDrawer from '@/views/systemManage/resourceManage/iconDrawer'
export default {
  components: {
    roleBindForm,
    addUserAuth,
    IconDrawer
  },
  name: "Dialog",
  data() {
    return {
      src: "",
      visible: false,
      title: "",
      width: "50%",
      fullscreen: false,
      top: "30vh",
      modal: true,
      modalAppendToBody: false,
      appendToBody: false,
      lockScroll: true,
      customClass: "",
      closeOnClickModal: true,
      closeOnPressEscape: true,
      showClose: true,
      center: false,
      destroyOnClose: false,
      className: "",
      height: "",
      ref: "dialog",
      container: null,
      formData: {},
      rules: {},
      params: {},
      value: "",
      btnText: "确 定",
      zIndex: 0,
      type: "",
      confirmFlag: true
    };
  },
  created() {
    console.clear();
    console.log("dialog-created");
    const dialog = document.getElementById("dialog");
    if (dialog) document.body.removeChild(dialog);
  },
  methods: {
    cancel() {
      this.reject("cancel"); // 抛个字符串
      this.hide("cancel");
    },
    closeIcon() {
      this.confirm()
    },
    confirm() {
      const hide = formData => {
        this.resolve(formData)
        this.hide()
      }
      if (this.$refs[this.type].validate) {
        this.$refs[this.type].validate ?
        this.$refs[this.type].validate(async valid => {
          if(!valid) return
          // 数据传送
          hide(this.formData)
        }) : hide(this.formData)
      } else {
        hide(this.formData)
      }
    },
    show(cb) {
      this.visible = true;
      typeof cb === "function" && cb.call(this, this);
      return new Promise((resolve, reject) => {
        // 返回Promise
        this.reject = reject; // 给取消按钮使用
        this.resolve = resolve; // 给确认按钮使用
      });
    },
    hide(type) {
      this.visible = false
      const dialog = document.getElementById('dialog')
      const dialogChild = document.querySelector('#dialog .rs-dialog__wrapper')
      dialogChild && dialog.removeChild(dialogChild)  // 结束移除Dom
      this.$destroy()  // 执行组件销毁
    },
    open() {
    },
    opened() {},
    close() {
      this.cancel();
    },
    closed() {},
    beforeClose(done) {
      done();
    }
  }
};
</script>
<style lang="less" scoped>
// /deep/.rs-dialog {
//   display: flex;
// }
/deep/.rs-dialog__header {
  display: flex;
}
/deep/.rs-dialog__body {
  font-size: 16px;

  // padding: 0!important;
  // flex: 1;
  // -webkit-box-flex: 1;
}
.form-dialog.editPrice-class /deep/.rs-dialog__header {
  font-size: 14px;
  padding: 10px 21px 10px 27px !important;
  /deep/.rs-dialog__headerbtn {
    top: 15px !important;
  }
}
.form-dialog.editPrice-class /deep/.rs-dialog__body {
  padding-bottom: 10px !important;
}
.btn-class /deep/.rs-dialog__footer {
  text-align: right !important;
}
.form-dialog.addBerth-class /deep/.rs-dialog__header {
  font-size: 14px;
  padding: 10px 21px 10px 27px !important;
  /deep/.rs-dialog__headerbtn {
    top: 15px !important;
  }
  .form-dialog /deep/.rs-dialog {
    position: absolute!important;
    left: 50%!important;
    top: 50%!important;
    transform: translate(-50%, -50%)!important;
  }
}
</style>
