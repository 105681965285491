var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        { attrs: { id: "dialog" } },
        [
          _c(
            "rs-dialog",
            {
              ref: _vm.ref,
              class: _vm.className,
              attrs: {
                visible: _vm.visible,
                width: _vm.width,
                title: _vm.title,
                fullscreen: _vm.fullscreen,
                top: _vm.top,
                modal: _vm.modal,
                "modal-append-to-body": _vm.modalAppendToBody,
                "append-to-body": _vm.appendToBody,
                "lock-scroll": _vm.lockScroll,
                "custom-class": _vm.customClass,
                "close-on-click-modal": _vm.closeOnClickModal,
                "close-on-press-escape": _vm.closeOnPressEscape,
                "show-close": _vm.showClose,
                "before-close": _vm.beforeClose,
                center: _vm.center,
                "destroy-on-close": _vm.destroyOnClose,
              },
              on: {
                "update:visible": function ($event) {
                  _vm.visible = $event
                },
                open: _vm.open,
                opened: _vm.opened,
                close: _vm.close,
                closed: _vm.closed,
              },
            },
            [
              _vm.type !== "IconDrawer"
                ? _c(_vm.type, {
                    ref: _vm.type,
                    tag: "components",
                    attrs: { formData: _vm.formData, rules: _vm.rules },
                  })
                : _vm._e(),
              _vm.type === "IconDrawer"
                ? _c(_vm.type, {
                    ref: _vm.type,
                    tag: "components",
                    attrs: { formData: _vm.formData, rules: _vm.rules },
                    on: { closeIcon: _vm.closeIcon },
                  })
                : _vm._e(),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "rs-button",
                    { attrs: { size: "small" }, on: { click: _vm.cancel } },
                    [_vm._v("取消")]
                  ),
                  _vm.confirmFlag
                    ? _c(
                        "rs-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: { click: _vm.confirm },
                        },
                        [_vm._v(_vm._s(_vm.btnText))]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }